import React from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
	<Layout>
		<SEO title="404 — Ryan Teuscher" />
		<nav>
			<p>
				Wrong turn. <Link to="/">Spin a uey</Link>
			</p>
		</nav>
	</Layout>
)
export default NotFoundPage
